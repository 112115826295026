import React, { useState } from "react";
import { Link } from "gatsby";
import Logo from "../images/crustdata_logo.png";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import { SITE_NAME, CRUSTDATA_PLATFORM_URL } from "../environment";
import Button from "@material-ui/core/Button";

import "../layout.css";
import { Spin as Hamburger } from "hamburger-react";
import { useMediaQuery } from "react-responsive";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import MailchimpSubscribe from "react-mailchimp-subscribe";
const url =
  "https://crustdata.us6.list-manage.com/subscribe/post?u=0d4bd3858165c768835e45b7f&amp;id=1dc605eed6";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    backgroundColor: "white",

    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  mobileFooter: {
    backgroundColor: "black",
    color: "white",
  },
  expandIcon: {
    color: "white",
  },
  searchPanel: {
    backgroundColor: "white",
  },
}));

const Layout = ({ location, title, children }) => {
  const [isOpen, setOpen] = useState(false);
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">
        <HeaderComponent
          Logo={Logo}
          SITE_NAME={SITE_NAME}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </header>
      {isOpen && (
        <div className="menu-dropdown">
          <div className="dropdown-link-container">
            <div className="dropdown-inner-container">
              {/* <form action="/search" class="search" role="search">
                <SearchIcon />
                <input
                  class="form-control"
                  placeholder="Search"
                  type="text"
                  name="q"></input>
                <div class="close-icon"></div>
              </form> */}
              {/* <Link className="dropdown-link" to="/category/techonology">
                TECHONOLOGY
              </Link> */}
              <Link className="dropdown-link" to="/category/market">
                Market
              </Link>
              <Link className="dropdown-link" to="/category/feature">
                Features
              </Link>
            </div>

            <button className="mobile-dropdown-button request-demo">
              Request Demo
            </button>
            <div className="dropdown-newsletter">
              <h5 className="mobile-newsletter-heading">
                SIGNUP FOR OUR NEWSLETTER
              </h5>
              {/* <p className="mobile-newsletter-subheading">
                Did you find this article useful? Then you might like to sign up
                to the newsletter!
              </p> */}
              <MailchimpSubscribe url={url} />
            </div>
            {/* <span class="copyright-footer">© Crustdata</span> */}
          </div>
          <div className="dropdown-footer">
            <FooterComponent SITE_NAME={SITE_NAME} />
          </div>
        </div>
      )}
      <main className="main">{children}</main>
      <FooterComponent SITE_NAME={SITE_NAME} />
    </div>
  );
};

function FooterComponent({ SITE_NAME }) {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1400 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1400 });
    return isMobile ? children : null;
  };
  const classes = useStyles();
  return (
    <div>
      <Desktop>
        <div class="footer-outter-container">
          <div class="footer-inner-container">
            {/* <div className="footer-column">
              <div className="footer-company-heading-container">
                <Link>
                  <h3 className="footer-li company-name">{SITE_NAME}</h3>
                </Link>
                <Link to="/">
                  <span className="footer-li">About</span>
                </Link>
                <Link to="/">
                  <span className="footer-li"> Explore Datasets</span>
                </Link>
              </div>
            </div> */}
            <div className="footer-column">
              <div className="footer-company-heading-container">
                <Link>
                  <h3 className="footer-li company-name">Contact Us</h3>
                </Link>
                <Link to="">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <MailOutlineIcon
                      style={{
                        margin: "10px",
                      }}
                    />
                    <span className="footer-li">info@crustdata.com</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="footer-newsletter">
              <h3 className="footer-newsletter-heading">
                Signup for our newsletter
              </h3>
              <MailchimpSubscribe url={url} />
            </div>
            <div className="footer-last-column">
              <Link to="https://twitter.com/crustdata">
                {" "}
                <TwitterIcon fontSize="large" />
              </Link>
              <Link to="https://www.linkedin.com/company/crustdata/about/">
                <LinkedInIcon fontSize="large" />
              </Link>
            </div>
          </div>
          <div className="desktop-copyright">
            ⓒ 2021 Crustdata. All rights reserved, Built with{" "}
            <span
              style={{
                fontSize: "32px",
                color: "red",
                margin: "0 4px",
              }}>
              {" "}
              ♥{" "}
            </span>{" "}
            in India
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="mobile-footer-container">
          {/* <div className="mobile-company-links">
            <Link>
              <span className="mobile-footer-list-container">{SITE_NAME}</span>
            </Link>
            <div className="mobile-footer-ul">
              <Link to="/">
                <span className="footer-li">About</span>
              </Link>
              <Link to="/">
                <span className="footer-li"> Explore Datasets</span>
              </Link>
            </div>
          </div> */}
          <div className="mobile-contactus">
            <span className="mobile-footer-list-container ">Contact Us</span>
            <Link to="">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <MailOutlineIcon
                  style={{
                    margin: "10px",
                  }}
                />
                <span className="footer-li">info@crustdata.com</span>
              </div>
            </Link>
          </div>
          <div className="mobile-copyright">
            ⓒ 2021 Crustdata. All rights reserved.
          </div>
        </div>
      </Mobile>
    </div>
  );
}

function HeaderComponent({ Logo, SITE_NAME, isOpen, setOpen }) {
  const classes = useStyles();
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1400 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1400 });
    return isMobile ? children : null;
  };
  return (
    <div className="header-outter">
      <Desktop>
        <div className="main-row">
          <div className="header-logo">
            <Link to="/">
              <img className="crustdata-logo" src={Logo} />
            </Link>
            <Link className="header-logo-link" to="/">
              <h4 className="header-logo-heading">{SITE_NAME}</h4>
            </Link>
          </div>
          <div className="header-links">
            <div className="header-links">
              {/* <Link to="/category/techonology">TECHONOLOGY</Link> */}
              <Link to="/category/market">MARKET</Link>
              {/* <Link to="/category/people">PEOPLE</Link> */}
              {/* <Link to="/category/innovation">INNOVATION</Link> */}
              <Link to="/category/feature">FEATURES</Link>
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="mobile-main-row">
          <div className="header-logo">
            <Link to="/">
              <img className="mobile-crustdata-logo" src={Logo} />
            </Link>
            <Link className="header-logo-link" to="/">
              <h4 className="header-logo-heading">{SITE_NAME}</h4>
            </Link>
          </div>
          <div className="header-links">
            <Hamburger color="white" toggled={isOpen} toggle={setOpen} />
          </div>
        </div>
      </Mobile>
    </div>
  );
}
export default Layout;
