export const SITE_NAME = "Crustdata";

export const CRUSTDATA_PLATFORM_URL = "https://crustdata.com/";
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoibWFubW9oaXQiLCJhIjoiY2tlZWV5ZG16MGZ1ZDM2dDVubXMzNzhveCJ9.q45pFfD1FaWKQ7JUx5TJ6A";
// https://www.businessofbusiness.com/articles/cannabis-jobs-linkedin-careers-hiring-weed-weedmaps-companies-growing-legalization/
export const Marker_COLORS = [
  "#a15ee0", // violet
  "#f7cb07", // Mustard
  "#e55e5e", // Tomato red
  "#12e385", // Green
  "#cf3acc", // Pink
  "#e05934", // Orange
  "#e3d430", // Lime
  "#3431e8", // Blue
  "#517cff",
  "#2cddb0",
  "#ffb300",
  "#487210",
  "#ee9b34",
  "#ff7803",
  "#f05cc9",
  "#710e89d4",
  "#2ef0f0d4",
  "#0dcf87de",
];
export const OVERLAY_COLORS = ["#fee5d9", "#fcae91", "#fb6a4a", "#cb181d"];
export const TILESET_LINE_COLOR = "rgba(111 ,109 ,109,0.50)";
export const MARKER_MAP_STYLE =
  "mapbox://styles/manmohit/ckq52jrwq04om18ohd4zkoe5f";
export const OVERLAY_MAP_STYLE =
  "mapbox://styles/manmohit/ckq555cms4y1w17mg5dsitx69";
export const TILESET_URL = "mapbox://manmohit.3s2pm25g";
export const TILESET_SOURCE_LAYER_ID = "district_tileset";
